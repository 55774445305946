@import '~@iqmetrix/antd/dist/accessibility.css';
@import '~@iqmetrix/style-tokens/dist/css/tokens-accessibility.css';
@import '~@iqmetrix/style-tokens/dist/scss/tokens-accessibility';

body {
  margin: 0;
  font-family: $font-family-base-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}